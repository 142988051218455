.table-goods {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  padding: 0;
  width: 100%;
}

.table-goods__form {
  display: flex;
  gap: 60px;
  padding: 0;
  margin-left: 30px;
  width: 100%;
  justify-content: flex-start;
}

.table-goods__form-content {
  display: none;
}

.table-goods__form-content_type_active {
  display: flex;
  gap: 60px;
}

.table-goods__form-content-column {
  border: 1px solid #000;
}

.table-goods__form-columns {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.table-goods__form-columns_type_name {
  text-align: center;
}

.table-goods__form-column {
  display: block;
  width: 60px;
  border: 1px solid #000;
  box-sizing: border-box;
  height: 20px;
}

.table-goods__form-column_type_name {
  font-size: 16px;
  font-weight: 600;
  padding: 3px;
  height: 29px;
}

.table-goods__form-column_type_label {
  width: 330px;
}

.table-goods__form-label {
  display: block;
  margin: 0;
  text-align: start;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  padding: 0 0 0 10px;
  width: 310px;
}

.table-goods__form-quantity {
  margin: 0;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  padding: 0;
}

.table-goods__form-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 196px;
  text-align: center;
  background-color: none;
  border: none;
}

.table-goods__form-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-goods__submit-button {
  width: 200px;
  height: 60px;
  font-size: 16px;
  font-weight: 600;
}

.table-goods__submit-button_type_active {
  background-color: aqua;
}

.table-goods__submit-button:hover {
  cursor: pointer;
}

@media print {
  .table-goods__submit-button {
    display: none;
  }

  .table-goods__form {
    width: 100%;
    max-width: 1260px;
  }

  .table-goods__form-column {
    height: 25px;
  }

  .table-goods__form-column_type_name {
    font-size: 18px;
    height: 35px;
  }

  .table-goods__form-label {
    padding-top: 5px;
    font-size: 14px;
  }

  .table-goods__form-quantity {
    padding-top: 5px;
    font-size: 14px;
  }
}